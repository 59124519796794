import { Container } from '@mui/material';
import ModalCalcSimplifiedCoverage from '~/modals/CalcSimplifiedCoverage';
import { useGetStepPlans } from '~/modals/CalcSimplifiedCoverage/hooks/use-get-plans';
import ErrorPage from '../Error';

export default function BasicModal() {
  const { data, loading, error } = useGetStepPlans();

  if (loading) {
    return <div> Loading </div>;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <Container maxWidth="sm">
      {data?.club ? (
        <ModalCalcSimplifiedCoverage steps={data?.steps} club={data.club} />
      ) : (
        <ModalCalcSimplifiedCoverage steps={data?.steps} />
      )}
    </Container>
  );
}
