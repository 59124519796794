import { Box, Checkbox, CircularProgress, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ModalHelpClub from '~/modals/ModalHelpClub';
import ModalHelpFinancialCharges from '~/modals/ModalHelpFinancialCharges';
import useStore from '~/store';
import { EquipmentValue } from './EquipmentValue';
import * as styles from './styles';
import { TypesOfEquipment } from './TypesOfEquipment';

interface Props {
  showProduct?: boolean;
  loading?: boolean;
}

interface ClubData {
  club_name: string;
  has_club: boolean;
  flat_rate: number;
  club_description: string;
}

const MessageList = ({ showProduct = false, loading = false }: Props) => {
  const [clubData, setClubData] = useState<ClubData>();

  const {
    quoteResponse,
    totalValue,
    serviceCharge,
    products,
    flat_rate,
    has_club,
    club_description,
    club_name,
    clubType,
    membershipFee
  } = useStore((store) => ({
    totalValue: store.totalValue,
    serviceCharge: store.serviceCharge,
    products: store.plan.products,
    quoteResponse: store.quoteResponse,
    flat_rate: store.flat_rate,
    has_club: store.has_club,
    club_name: store.club_name,
    club_description: store.club_description,
    clubType: store.quoteResponse?.type_club,
    membershipFee: store.quoteResponse?.membership_fee
  }));

  useEffect(() => {
    if (quoteResponse) {
      setClubData({
        club_name: quoteResponse.plan_club_name,
        has_club: quoteResponse.plan_has_club,
        flat_rate: quoteResponse.flat_rate,
        club_description: quoteResponse.plan_club_description
      });
    }

    if (has_club) {
      setClubData({
        club_name: club_name,
        has_club: has_club,
        flat_rate: flat_rate,
        club_description: club_description
      });
    }
  }, [quoteResponse, flat_rate, has_club, club_name]);

  const priceFormatter = (value: number) => {
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL'
    }).format(value);

    return formattedValue;
  };

  return (
    <div style={{ minWidth: '100%' }}>
      <Typography variant="h3" sx={styles.descritionTitle}>
        Detalhes da contratação
      </Typography>
      <Divider sx={styles.divider} />
      <Typography variant="h6" fontWeight="bold">
        Equipamento segurado:
      </Typography>
      <Box sx={{ minWidth: '100%' }}>
        <TypesOfEquipment />
      </Box>
      <Box sx={{ minWidth: '100%' }}>
        <EquipmentValue />
      </Box>
      {showProduct && (
        <>
          <Typography variant="h6" fontWeight="bold" sx={styles.divider}>
            Serviço(s) contratado(s):
          </Typography>
          {products?.length > 0 &&
            products.map((product) => {
              return (
                <Box key={product.name} sx={() => styles.containerMessage}>
                  <Checkbox
                    checked
                    inputProps={{
                      'aria-label': product.name
                    }}
                    color="success"
                  />
                  <Typography sx={styles.productName} variant="h6" fontWeight="bold" color="primary">
                    {product.name}
                  </Typography>
                </Box>
              );
            })}
        </>
      )}
      <Divider sx={styles.amountDivider} />

      {clubType === 2 ? (
        <>
          <Box sx={styles.containerAmount}>
            <Box component="section" sx={styles.FinancialCharges}>
              <Typography variant="body1">Taxa de adesão</Typography>
            </Box>
            <Typography variant="body1" sx={styles.amountStyle}>
              {loading ? <CircularProgress size={16} /> : '1x ' + priceFormatter(membershipFee)}
            </Typography>
          </Box>
          <Box sx={styles.containerAmount}>
            <Typography variant="body1" sx={styles.totalStyle}>
              Plano anual {clubData?.club_name}
            </Typography>
            <Typography variant="body1" sx={styles.amountStyle}>
              12x {loading ? <CircularProgress size={20} /> : priceFormatter(totalValue)}
            </Typography>
          </Box>
        </>
      ) : (
        <>
          {!!clubData && clubData.has_club ? (
            <>
              <Box sx={styles.containerAmount}>
                <Box component="section" sx={styles.FinancialCharges}>
                  <Typography variant="body1">{clubData.club_name}</Typography>
                  <ModalHelpClub clubDiscription={club_description} />
                </Box>
                <Typography variant="body1">
                  {loading ? <CircularProgress size={16} /> : priceFormatter(clubData.flat_rate)}
                </Typography>
              </Box>
            </>
          ) : null}

          <Box sx={styles.containerAmount}>
            <Box component="section" sx={styles.FinancialCharges}>
              <Typography variant="body1">Encargos financeiros</Typography>
              <ModalHelpFinancialCharges />
            </Box>
            <Typography variant="body1">
              {loading ? <CircularProgress size={16} /> : priceFormatter(serviceCharge)}
            </Typography>
          </Box>

          <Divider sx={styles.clubDivider} />

          <Box sx={styles.containerAmount}>
            <Typography variant="body1" sx={styles.totalStyle}>
              Valor total
            </Typography>
            <Typography variant="h6" sx={styles.amountStyle}>
              12x {loading ? <CircularProgress size={20} /> : priceFormatter(totalValue)}
            </Typography>
          </Box>
          {membershipFee > 0 && (
            <>
              <Divider sx={styles.amountDivider} />
              <Box sx={styles.containerAmount}>
                <Typography variant="body1" sx={styles.totalStyle}>
                  Taxa de adesão
                </Typography>
                <Typography variant="h6" sx={styles.amountStyle}>
                  1x {loading ? <CircularProgress size={20} /> : priceFormatter(membershipFee)}
                </Typography>
              </Box>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default MessageList;
