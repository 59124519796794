import { IconProp } from '@fortawesome/fontawesome-svg-core';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Box, Card, Checkbox, Divider, Grid, Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Icon } from '~/components/Icon';
import useStore from '~/store';
import { Product } from '~/store/slices/quote/types';
import * as styles from './styles';
import { CardDetailProps, CardPriceProps } from './types';

const CardDetail: React.FC<CardDetailProps> = ({ title, isSelected, icon }) => (
  <Box sx={styles.containerCoverage}>
    <Box sx={styles.containerIcon(isSelected)}>
      <Icon icon={icon as IconProp} />
    </Box>
    <Typography variant="body2" color="white">
      {title}
    </Typography>
  </Box>
);

const CardPrice: React.FC<CardPriceProps> = ({
  isSelected,
  product_price,
  selectProduct,
  product_id,
  product_name,
  product_description,
  cover,
  checked,
  required,
  handleDefaultSelectedProducts
}) => {
  const [openDetails, setOpenDetails] = useState(false);
  const [showAllDescription, setShowAllDescription] = useState(false);

  const { clubType } = useStore((store) => {
    return {
      clubType: store.quoteResponse?.type_club
    };
  });

  const product: Product = {
    product_id,
    product_price,
    product_name,
    product_description
  };

  function handleSelectProduct() {
    if (!required) selectProduct(product);
  }

  useEffect(() => {
    if (checked || required) {
      handleDefaultSelectedProducts(product);
    }
  }, []);

  return (
    <>
      <Card sx={styles.headerContainer(isSelected)}>
        <Box sx={styles.container}>
          <Box sx={styles.headerTitle}>
            <Checkbox
              color="success"
              disabled={required}
              sx={styles.checkbox}
              checked={isSelected}
              onChange={handleSelectProduct}
            />
            <Typography sx={styles.productName}>{product_name}</Typography>
          </Box>
          {clubType !== 2 && (
            <Typography sx={styles.cardPrice}>
              {`12x `}
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(product_price)}
            </Typography>
          )}
        </Box>
        <Typography sx={{ fontSize: { xs: 14, sm: 16 } }} fontWeight="bold">
          {product_description.length > 120
            ? !showAllDescription
              ? `${product_description.substring(0, 120)}...`
              : product_description
            : product_description}
          <Typography component="span" fontWeight="regular" sx={{ ml: 2, cursor: 'pointer' }}>
            <Link
              onClick={() => {
                setShowAllDescription(!showAllDescription);
              }}
              underline="none"
            >
              {showAllDescription ? 'Ver menos' : 'Ver mais'}
            </Link>
          </Typography>
        </Typography>
        <Typography>Coberturas inclusas:</Typography>
        <Typography
          color="#54C556"
          fontWeight="bold"
          sx={styles.moreDetails}
          onClick={() => setOpenDetails(!openDetails)}
        >
          {openDetails ? '- detalhes' : '+ detalhes'}
        </Typography>
        {openDetails && (
          <>
            <Divider sx={styles.textDetail} />
            {cover.slice(0, 4).map((cover, index) => (
              <Box key={index}>
                <Typography sx={styles.textDetail}>
                  <CheckRoundedIcon color="success" sx={styles.iconDetails} />
                  {cover.cover_name}
                </Typography>
              </Box>
            ))}
            <Divider sx={styles.textDetail} />
          </>
        )}
        <Grid container>
          {cover.map((item, index) => (
            <Grid item key={index} display={'flex'} justifyContent={'center'} xs={6} sm={12 / cover.length}>
              <CardDetail title={item.cover_name} isSelected={isSelected} icon={item.cover_icon} />
            </Grid>
          ))}
        </Grid>
      </Card>
    </>
  );
};

export default CardPrice;
