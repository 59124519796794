import Color from '~/styles/colors';
export const headerContainer = {
  display: 'flex',
  padding: 2,
  alignItems: 'flex-start'
};
export const iconGoback = {
  marginRight: 1
};
export const container = {
  marginTop: 4,
  marginBottom: 4
};
export const description = {
  textAlign: 'center',
  color: Color?.primary?.main || '#A547FF',
  fontWeight: 'bold',
  fontSize: { xs: 20, sm: 24 },
  marginTop: 2
};
export const subTitle = {
  cursor: 'pointer',
  marginTop: 1,
  textAlign: 'center',
  color: '#03DAC5',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: { xs: 14, sm: 16 }
};
export const dividerStyle = {
  marginTop: 2,
  marginBottom: 2,
  borderColor: Color?.primary?.main || '#A547FF'
};
export const helpIcon = {
  marginRight: 1
};
export const containerButton = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 2
};
export const containerAmount = {
  marginTop: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};
export const containerResume = {
  textAlign: 'center'
};
export const containerServiceCharge = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};
export const amountStyle = {
  color: '#A547FF',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center'
};
export const amountDivider = {
  marginY: 1,
  borderColor: '#323232'
};
export const containerTotal = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '24rem'
};
export const totalStyle = {
  fontWeight: 'bold'
};
export const descritionTitle = {
  fontSize: 16,
  textAlign: 'center',
  marginBottom: 1
};
export const butttonStyle = {
  marginTop: 2
};

export const FinancialCharges = {
  display: 'flex',
  alignItems: 'center',
  gap: 0.2
};
